import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Slice, Event } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { 
  ColorStrip,
  useContentSlice, useEntity, useLang,
  SliceSectionColorBlock, EventSessionTabs,
  SessionList,
  SliceLayout,
  ActiveEntityProvider,
  PAGE_EVENTS,
  COLOR_WHITE,
  ContentNode,
  ContentStyles,
} from '@shapeable/ui';

import { 
  classNames, 
  eventDateRange,
  formatDateString,
  formatDate,
} from '@shapeable/utils';

import {
  EntityTitleHeader,
  SliceLayoutContentImage,
} from '@shapeable/web';

import { BLUE, BROWN_OVERLAY, LIGHT_BIEGE, ORANGE, VERY_LIGHT_BROWN } from '../../theme';
const cls = classNames('event-layout');
import { uniq, filter, find, get } from 'lodash';
import { SliceLayoutColorStrip } from '../slice-layouts';

// -------- Types -------->

export type EventLayoutProps = Classable & HasChildren & { 
  entity?: Event;
};

export const EventLayoutDefaultProps: Omit<EventLayoutProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {

};

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
  `,
  tablet: css`
  `
});

const ColorStripStyles = breakpoints({
  base: css`

  `,
});

const BodyStyles = breakpoints({
  base: css`
  `,
});

const TabsStyles = breakpoints({
  base: css`
    position: relative;
    width: 100%;
    padding-top: ${theme.UNIT(2)};
    padding-bottom: ${theme.UNIT(6)};
    overflow: hidden;
  `,
});

const AgendaStyles = breakpoints({
  base: css`
    iframe[src*="youtube"] {
      width: 320px;
      height: 180px;
    }
  `,
  landscape: css`
    iframe[src*="youtube"] {
      width: 500px;
      height: 282px;
    }
  `,
  tablet: css`
    font-size: ${theme.FONT_SIZE(18)};
    iframe[src*="youtube"] {
      width: 768px;
      height: 432px;
    }
  `,
  desktop: css`
    font-size: ${theme.FONT_SIZE(20)};
    iframe[src*="youtube"] {
      width: 1024px;
      height: 576px;
    }
  `,
});

const StickyStyles = breakpoints({
  base: css`
    top: 0px;
    position: sticky;
    z-index: 20;
    width: 100%;
  `,
});

const SessionsStyles = breakpoints({
  base: css`
    padding-bottom: ${theme.UNIT(10)};
  `,
});

const BannerStyles = breakpoints({
  base: css`

    h1 {
      font-size: ${theme.FONT_SIZE(48)};
      font-weight: 500;
      line-height: 1.1em;
      margin-top: ${theme.UNIT(4)};
      margin-bottom: ${theme.UNIT(4)};
    }
  `,
  tablet: css`
    max-height: 600px;

    h1 {
      margin-bottom: ${theme.UNIT(6)};
      font-size: ${theme.FONT_SIZE(80)};
    }
  `
});



 

// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
  ColorStrip: styled(ColorStrip)`${ColorStripStyles}`,
  
  Content: styled(ContentNode)`${ContentStyles}`,
  Banner: styled(SliceLayoutContentImage)`${BannerStyles}`,
  
  Tabs: styled(EventSessionTabs)`${TabsStyles}`,

  Body: styled.div`${BodyStyles}`,

  Agenda: styled.div`${AgendaStyles}`,
  Sticky: styled.div`${StickyStyles}`,
  Sessions: styled(SessionList)`${SessionsStyles}`,
};

export const EventLayout: React.FC<EventLayoutProps> = (props) => {
  const { className, children } = props;
  const entity = useEntity(props.entity);
  
  const t = useLang();
    
  const {
    banner, description, startDate, endDate, 
    images, links, overview, sessionInfo
  } = entity;

  const bodyRef = React.useRef<HTMLDivElement>();

  const sessions = filter(entity.sessions || [], session => !!session.startDate);

  const days = uniq(sessions.map(session => formatDateString(session.startDate, 'YYYY-MM-DD')));

  const today = formatDate(new Date(), 'YYYY-MM-DD');

  const defaultActiveDay = find(days, day => day === today) || (days.length && days[0]);

  const [ activeDay, setActiveDay ] = React.useState<string>(defaultActiveDay);

  const activeSessions = filter(sessions, session => (formatDateString(session.startDate, 'YYYY-MM-DD') === activeDay));

  const hasSessionInfo = !!sessionInfo?.text;
  const hasOverview = !!overview?.text;
  const hasContent = hasSessionInfo || hasOverview;

  const subtitle = eventDateRange(startDate, endDate);

  const bannerSlice = useContentSlice({
    title: t('Welcome'),
    subtitle,
    intro: description,
    backgroundImages: filter([banner]),
    images,
    links,
  });

  const tabOnClick = (day: string) => {
    setActiveDay(day); 
  };
  
  return (
    <ActiveEntityProvider value={{ ...PAGE_EVENTS, path: '/events-activities-glc' }}>
    <My.Container className={cls.name(className)}>
      <EntityTitleHeader backgroundColor={LIGHT_BIEGE} overlayColor={BROWN_OVERLAY} entity={entity} />

      <My.Banner entity={bannerSlice} />

      <My.Body ref={bodyRef}>
        <My.Agenda id="agenda">
          <SliceLayout verticalPadding='small' boundary='content-bleed'>

          {
            hasContent && 
            <SliceLayout verticalPadding='content'>
            {
              overview && <My.Content entity={overview} />
            }
            {
              sessionInfo && <My.Content entity={sessionInfo} />
            }
            </SliceLayout>
          }


          <My.Sticky>
            <My.Tabs
              entity={entity} 
              activeColor={ORANGE}
              activeDay={activeDay}
              lineColor={VERY_LIGHT_BROWN}
              lineWidth={2}
              lineVariant="dotted"
              onDateChange={tabOnClick}
            />
          </My.Sticky>

          <My.Sessions groupByTimeOfDay items={activeSessions} />
          </SliceLayout>
        </My.Agenda>

      </My.Body>

    </My.Container>
    </ActiveEntityProvider>
  )
};

EventLayout.defaultProps = EventLayoutDefaultProps;